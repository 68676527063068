type ConfigType = {
    env: string,
    accessTokenName: string,
    userTokenName: string,
    language: string,
    baseUrl?: string,
    itemsPerPage: number,
    userRoles: {guest: number, user: number},
    validationToken: string
};

let config: ConfigType = {
    env: 'prod',
    accessTokenName: 'access_token',
    userTokenName: 'user_token',
    language: 'language',
    itemsPerPage: 10,
    userRoles: {
        'guest': 0,
        'user': 1
    },
    validationToken: 'validation_token',
};

if (config.env === 'prod') {
    config['baseUrl'] = 'https://api.bonusukarte.lv';
} else if (config.env === 'dev') {
    config['baseUrl'] = 'https://api.bk.micron.lv';
} else {
    config['baseUrl'] = 'http://127.0.0.1:5001';
}

export default Object.freeze(Object.assign({}, config));
